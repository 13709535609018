<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('irrigation_task.task_report') }} </h4>
            </template>
            <template v-slot:body>
                <form-search :data="searchAttributes"
                             @returned-data="searchData"
                             @picker-instance="getDateInstance"
                             @change="setData"
                />
            </template>
        </iq-card>
        <b-row>
            <b-col md="12" v-if="listData.length > 0">
                <iq-card v-if="listType === 2">
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('irrigation_task.task_report') }} </h4>
                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new mr-2" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                        </b-button>
                        <export-excel
                            class="btn btn_add_new"
                            :data="dataCustomize"
                            :fields= "json_fields"
                            :title="$t('irrigation_task.task_report')"
                            worksheet="$t('irrigation_task.task_report')"
                            name="task-report.xls">
                            <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                        </export-excel>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="load">
                            <b-row>
                                <b-col>
                                    <list-report-head :base-url="irriSchemeServiceBaseUrl"
                                        uri="report-heading/detail"
                                        :org-id="search.org_id"
                                        :loading-state="load"
                                        :key="reloadListHeading">
                                        {{ $t('irrigation_task.task_report')}}
                                    </list-report-head>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template #thead-top>
                                            <b-tr>
                                                <b-th colspan="6" class="text-center font-size-18">{{ $t('irrigation_task.task')}}</b-th>
                                                <b-th colspan="6" class="text-center font-size-18">{{ $t('irrigation_task.task_report')}}</b-th>
                                                <b-th></b-th>
                                            </b-tr>
                                        </template>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(org_name)="data">
                                            {{ getOrgName(data) }}
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            {{ data.item.status ? $t('globalTrans.inactive') : $t('globalTrans.active') }}
                                        </template>
                                        <template #cell(task_report_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>Link</a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_assign_attachment)="data">
                                            <a v-if="data.value" :href="data.value" target="_blank" download>Link</a>
                                            <span v-else>N/A</span>
                                        </template>
                                        <template #cell(task_id)="data">
                                            <div>{{ $n(data.value) }}</div>
                                        </template>
                                        <template #cell(assign_username)="data">
                                            <div>{{ data.value }}</div>
                                        </template>
                                        <template #cell(task_date)="data">
                                            <div>{{ data.value | dateFormat }}</div>
                                        </template>
                                        <template #cell(task_reports_note)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                        <template #cell(task_reports_note_bn)="data">
                                            <div v-html="data.value"></div>
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>

                <iq-card v-if="listType === 1">
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.my_daily_task') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="load">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" bordered hover :items="listData" :fields="dailyTaskColumns">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + 1) }}
                                        </template>
                                        <template v-slot:cell(org_name)="data">
                                            {{ getOrgName(data) }}
                                        </template>
                                        <template v-slot:cell(meter_reading_before)="data">
                                            {{ $n(data.item.meter_reading_before) }}
                                        </template>
                                        <template v-slot:cell(meter_reading_after)="data">
                                            {{ $n(data.item.meter_reading_after) }}
                                        </template>
                                        <template v-slot:cell(pump_running_time)="data">
                                            {{ $n(data.item.pump_running_time) }}
                                        </template>
                                        <template v-slot:cell(irrigation_area)="data">
                                            {{ $n(data.item.irrigation_area) }}
                                        </template>
                                        <template v-slot:cell(created_at)="data">
                                            <span :title="data.item.created_at | dateFormat">{{ data.item.created_at | dateFormat}}</span>
                                        </template>
                                        <template v-slot:cell(task_status)="data">
                                            {{ data.item.task_status === 1 ? $t('globalTrans.pending') : (data.item.task_status === 2 ? $t('globalTrans.submitted') : 'N/A') }}
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="dataChange"
                                    />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
            <b-col v-else md="12">
                <b-card v-if="clicked">
                    No Data
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { taskReportsList } from '../../api/routes'
import { Common, helpers, List } from '@/mixins/helper-functions'
import FormSearch from '@/components/structure/form/search/FormSearch'
import ExportPdf from './export-pdf_details'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { mapGetters } from 'vuex'

Vue.use(excel)

const excelColumn = {
    'Serial No': 'sl_no',
    Organization: 'org',
    'Task ID': 'taskId',
    'Task Name': 'taskName',
    Responsible: 'respon',
    Schedule: 'sch',
    'Task Note': 'taskNote',
    'Task Calendar Date': 'taskCD'
}
const excelColumnBn = {
    'ক্রমিক নং': 'sl_no',
    সংস্থা: 'org',
    'কার্যের আইডি': 'taskId',
    'কার্যের নাম': 'taskName',
    দায়িত্বপ্রাপ্ত: 'respon',
    সময়সূচী: 'sch',
    'কার্যের নোট': 'taskNote',
    'কার্য ক্যালেন্ডার তারিখ': 'taskCD'
}
export default {
    name: 'TaskReportList',
    mixins: [List, Common],
    components: {
        FormSearch,
        ListReportHead
    },
    data () {
        return {
            irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
            load: false,
            taskId: null,
            search: {
                org_id: '',
                from_date: '',
                to_date: '',
                task_id: '',
                task_name: '',
                task_status: '',
                type: ''
            },
            testId: 0,
            rows: [],
            defaultNull: '',
            taskReport: {
                attachment: []
            },
            taskStatusList: [],
            dateInstanceStore: [],
            dateInstance: [],
            showTaskName: true,
            reloadSearchComponent: 0,
            listType: 2,
            clicked: false,
            reloadListHeading: 1
        }
    },
    computed: {
        ...mapGetters({
            authUser: 'Auth/authUser'
        }),
        json_fields: function () {
            return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
        },
        dataCustomize () {
            try {
                if (typeof this.listData !== 'undefined') {
                    const listData = this.listData
                    var slNo = 1
                    listData.map(item => {
                        if (this.$i18n.locale === 'bn') {
                            item.sl_no = this.$n(slNo++)
                            item.org = item.org_name_bn
                            item.taskId = item.task_id
                            item.taskName = item.task_name_bn
                            item.respon = item.assign_username
                            item.sch = item.schedule
                            item.taskNote = item.task_reports_note_bn
                            item.taskCD = item.task_date
                        } else {
                            item.sl_no = slNo++
                            item.org = item.org_name
                            item.taskId = item.task_id
                            item.taskName = item.task_name
                            item.respon = item.assign_username
                            item.sch = item.schedule
                            item.taskNote = item.task_reports_note
                            item.taskCD = item.task_date
                        }
                        return Object.assign({}, item)
                    })
                    return listData
                } else {
                    return []
                }
            } catch (e) {
                return []
            }
        },
        taskTypeList () {
            return this.$store.state.commonObj.irrigationTaskTypeList
        },
        orgList () {
            return this.$store.state.commonObj.organizationProfileList
        },
        formTitle () {
            return this.$t('irrigation_task.task_report') + ' ' + (this.testId === 0) ? this.$t('globalTrans.entry') : this.$t('globalTrans.modify')
        },
        listData () {
            return this.$store.state.IrriTask.taskReportsList
        },
        dailyTaskColumns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('org_pro.organization'), class: 'text-left' },
                { label: this.$t('globalTrans.name'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.meterReadingBefore'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.meterReadingAfter'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.pumpRunnigTime'), class: 'text-left' },
                { label: this.$t('warehouseFarmer.irriArea'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('warehouseFarmer.date'), class: 'text-left' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'name' },
                { key: 'meter_reading_before' },
                { key: 'meter_reading_after' },
                { key: 'pump_running_time' },
                { key: 'irrigation_area' },
                { key: 'task_status' },
                { key: 'created_at' }
            ]
            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'org_name_bn' }
                keys[2] = { key: 'name_bn' }
            }
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
                { label: this.$t('org_pro.organization'), class: 'text-left' },
                { label: this.$t('globalTrans.name'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_id'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_name'), class: 'text-left' },
                { label: this.$t('irrigation_task.responsible'), class: 'text-left' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('globalTrans.schedule'), class: 'text-left' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.attachment'), class: 'text-center' },
                { label: this.$t('irrigation_task.note'), class: 'text-left' },
                { label: this.$t('irrigation_task.task_calendar_date'), class: 'text-left' }
            ]

            var keys = [
                { key: 'index' },
                { key: 'org_name' },
                { key: 'name' },
                { key: 'task_id' },
                { key: 'task_name' },
                { key: 'assign_username' },
                { key: 'task_assign_attachment' },
                { key: 'schedule' },
                { key: 'status' },
                { key: 'task_report_attachment' },
                { key: 'task_reports_note' },
                { key: 'task_date' }
            ]

            if (this.$i18n.locale === 'bn') {
                keys[1] = { key: 'org_name_bn' }
                keys[2] = { key: 'name_bn' }
                keys[3] = { key: 'task_id' }
                keys[4] = { key: 'task_name_bn' }
                keys[4] = { key: 'assign_username' }
                keys[9] = { key: 'task_reports_note_bn' }
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        isOrgAdmin () {
          return this.$store.state.Auth.authUser.is_org_admin
        },
        searchAttributes () {
            const vm = this
            return {
                buttonText: this.$t('globalTrans.search'),
                data: [
                    {
                        name: 'org_id',
                        value: this.search.org_id,
                        type: 'Select',
                        readOnly: !!this.isOrgAdmin,
                        label: 'org_pro.organization',
                        options: this.orgList,
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'type',
                        type: 'Select',
                        label: 'globalTrans.type',
                        options: [
                            {
                                text: this.$t('irrigation_task.daily_task_report'),
                                value: 1
                            },
                            {
                                text: this.$t('irrigation_task.assign_task_report'),
                                value: 2
                            }
                        ],
                        selectTitle: this.$t('globalTrans.select')
                    },
                    {
                        name: 'task_name',
                        type: 'Input',
                        label: 'irrigation_task.task_name',
                        readOnly: !this.showTaskName
                        // show: this.showTaskName
                    },
                    {
                        name: 'task_id',
                        type: 'Input',
                        label: 'irrigation_task.task_id'
                    },
                    {
                        name: 'from_date',
                        type: 'Datepicker',
                        label: 'globalTrans.from_date',
                        config: {
                            onChange: function (selectedDates, dateStr) {
                                const toDate = vm.dateInstance[1].to_date
                                toDate.set('minDate', dateStr)
                                toDate.toggle()
                            }
                        }
                    },
                    {
                        name: 'to_date',
                        type: 'Datepicker',
                        label: 'globalTrans.to_date',
                        config: {
                            onChange (selectedDates, dateStr) {
                                const fromDate = vm.dateInstance[0].from_date
                                fromDate.set('maxDate', dateStr)
                            }
                        }
                    }/* ,
                    {
                        name: 'task_status',
                        type: 'Select',
                        label: 'globalTrans.status',
                        options: this.taskStatusList,
                        selectTitle: this.$t('globalTrans.select')
                    } */
                ]
            }
        }
    },
    watch: {
        load (newVal, oldVal) {
            if (newVal) {
                this.loadData()
            }
        },
        'search.type': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal && newVal === 1) {
                    this.showTaskName = false
                } else {
                    this.showTaskName = true
                }
                this.reloadSearchComponent++
            }
        }
    },
    created () {
        if (this.authUser.role_id === 1) {
            this.search = Object.assign({}, this.search, {
                org_id: 0
            })
        } else {
            this.search = Object.assign({}, this.search, {
                org_id: this.authUser.office_detail.org_id
            })
        }
        this.$store.dispatch('IrriTask/irriTaskReportsList', [])
    },
    methods: {
        setData (data) {
            Object.assign(this.search, data)
            if (typeof data.type !== 'undefined' && data.type === 1) {
                this.showTaskName = false
            } else {
                this.showTaskName = true
            }
        },
        getOrgName (data) {
          return this.$i18n.locale === 'en' ? data.item.org_name : data.item.org_name_bn
        },
        getDateInstance (payload) {
            this.dateInstanceStore.push(payload)
            if (this.dateInstanceStore.length > 1) {
                this.dateInstance = this.dateInstanceStore
            }
        },
        resetId () {
            this.testId = 0
        },
        dataChange () {
            this.loadData()
        },
        default () {
            return {
                id: this.rows.length,
                editable: false
            }
        },
        searchData (payload) {
            if (!this.search.org_id) {
                const msg = this.$i18n.locale === 'bn' ? 'দয়া করে একটি সংস্থা নির্বাচন করুন!' : 'Please select an organization!'
                return alert(msg)
            }
            this.search = payload
            this.clicked = true
            this.reloadListHeading++
            this.loadData()
        },
        loadData () {
            // const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.load = true
            RestApi.getData(irriSchemeServiceBaseUrl, taskReportsList, this.search).then(async (response) => {
                this.$store.dispatch('IrriTask/irriTaskReportsList', [])
                if (response.success) {
                    this.listType = parseInt(response.type) || 2
                    if (this.listType === 2) {
                        const data = await this.dataList(response)
                        if (typeof data.data !== 'undefined') {
                            const listData = data.data.filter(item => typeof item !== 'undefined')
                            await this.$store.dispatch('IrriTask/irriTaskReportsList', listData)
                            this.paginationData(data.pagination)
                        }
                    } else {
                        const res = response.data.data.map(item => {
                            const orgTmp = this.$store.state.orgList.find(orgItem => orgItem.value === item.org_id)
                            item.org_name = orgTmp.text_en
                            item.org_name_bn = orgTmp.text_bn
                            return item
                        })
                        this.$store.dispatch('IrriTask/irriTaskReportsList', res)
                        this.paginationData(response.data)
                    }
                    this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
                }
                this.load = false
            }).catch((error) => {
                if (error.response) {
                    this.load = false
                }
            })
        },
        async dataList (response) {
            if (response.data.data.length > 0) {
                const orgList = this.$store.state.orgList
                let listData = {}
                listData = await response.data.data.map(item => {
                    const orgTmp = orgList.find(orgItem => orgItem.value === item.org_id)
                    item.org_name = orgTmp.text_en
                    item.org_name_bn = orgTmp.text_bn
                    const data = {
                        task_assign_attachment: item.task_assign_attachment ? irriSchemeServiceBaseUrl +
                            'uploads/task-assign-tasks/original/' +
                            item.task_assign_attachment : null,
                        task_report_attachment: item.task_reports_attachment ? irriSchemeServiceBaseUrl +
                            'uploads/task-reports/original/' +
                            item.task_reports_attachment : null,
                        task_reports_note: helpers.stringLimit(item.task_reports_note, 10),
                        task_reports_note_bn: helpers.stringLimit(item.task_reports_note_bn, 10)
                    }
                    return Object.assign({}, item, data)
                })
                delete response.data.data
                return {
                    data: listData,
                    pagination: response.data
                }
            }
        },
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Task Report' : 'টাস্ক রিপোর্ট'
            ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, this.listData, this)
        }
    }
}
</script>
